@import 'src/App.scss';

.parallax-section {
  background-color: #000000e6;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &-bgImage {
    height: 100vh;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(3, 3, 2, 0.15);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;

      background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }

    @include maxwidth(sm) {
      background-attachment: inherit;
    }
  }

  &-content {
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto;
    padding: 80px 32px;

    @include maxwidth(sm) {
      width: 90%;
      padding: 32px 8px;
      text-align: center;
    }

    &-title {
      text-align: center;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 60px;
      font-weight: 300;
      font-family: Raleway;
      margin-bottom: 0;

      @include maxwidth(sm) {
        font-size: 24px;
      }
    }
  }
  &-footer {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 0;
    left: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &-button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;

      &:focus,
      &:active {
        outline: 0;
      }

      &:hover {
        > img {
          transition-duration: 300ms;
          transition-timing-function: ease-out;
          transform: translateY(8px);
          opacity: 0.8;
        }

        > p {
          opacity: 0.8;
        }
      }

      &-text {
        font-weight: 500;
        font-size: 13px;
        width: 100%;
        text-align: center;
        margin-bottom: 4px;
        color: rgb(207, 203, 197);
      }

      > img {
        width: 40px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(199deg) brightness(104%)
          contrast(101%);
      }
    }
  }
}
