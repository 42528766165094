.image-zoom {
  width: 100%;
  height: 100%;
  position: relative;

  &:hover &-img {
    opacity: 0.8;
  }
  &-img {
    width: 100%;
    height: 100%;
    transition: opacity ease-out 200ms;
    cursor: pointer;
  }
}
