@import 'src/App.scss';

.contact {
  background-color: #fff;

  &-section-1 {
    background-color: #4d4d4d;
    width: 100%;
    height: 100vh;
    margin: auto;
  }

  &-section-2 {
    margin-top: 120px;
    margin-bottom: 120px;
    width: 100%;
    color: #555;

    @include maxwidth(sm) {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    h3 {
      font-weight: 600;
      color: #215166;
    }

    textarea,
    input {
      background: #fafafa;
      border-radius: 2px;
    }

    button {
      background: rgb(77, 77, 77);
      // background-color: #272727;
      padding: 1em 2.5em;
      color: #fff;
      font-size: 14px;
      border: none;
      border-radius: 0;
    }
  }
}
