@import 'src/App.scss';

$gridSpacing: 56px;

.porcelain {
  &-section-text {
    padding: 100px 0;

    @include maxwidth(sm) {
      padding: 50px 0;
    }

    &-title {
      font-weight: 600;
      color: #215166;
    }
  }

  &-grid-section {
    columns: 3 200px;
    column-gap: $gridSpacing;
    padding: $gridSpacing;

    @include maxwidth(sm) {
      padding: 16px;
    }

    > div {
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-bottom: $gridSpacing;
    }
  }
}
