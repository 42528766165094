@import 'src/App.scss';

.contact-box {
  display: flex;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 4;
  visibility: hidden;

  @include maxwidth(sm) {
    bottom: 15px;
    right: 15px;
  }

  &-message-wrapper {
    margin-right: 20px;
    opacity: 0;
    transform-origin: center right;

    &.show {
      opacity: 1;
      animation: showBubble 250ms ease-out;
    }

    &.hide {
      opacity: 0;
      animation: hideBubble 200ms ease-out;
    }
  }
  &-message {
    font-family: Raleway;
    background: #fff;
    color: #000;
    font-size: 14px;
    line-height: 13px;
    padding: 20px 16px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    margin-right: 8px;

    &:after {
      content: '';
      position: absolute;
      right: 70px;
      top: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #fff;
      border-right: 0;
      border-top: 0;
      margin-top: -10px;
    }
  }
  &-image-container {
    transition: transform ease-out 150ms;

    > img {
      width: 50px;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.contact-box.animated {
  visibility: visible;
  animation: fadeUp 0.5s ease-out;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    bottom: -60px;
  }
  100% {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showBubble {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hideBubble {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}
