@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';

html,
body {
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
}

.App {
  text-align: center;
  font-family: 'Raleway', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-aos='fade-in-0-7'] {
  opacity: 0;
  transition-property: opacity;

  &.aos-animate {
    opacity: 0.7 !important;
  }
}

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

@mixin minwidth($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin maxwidth($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@font-face {
  font-family: 'ActaDisplayBook"';
  src: local(ActaDisplayBook), url('assets/fonts/ActaDisplay.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local(Raleway), url('assets/fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedRegular';
  src: local(RobotoCondensedRegular),
    url('assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedLight';
  src: local(RobotoCondensedLight), url('assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
}

.carousel {
  .control-next.control-arrow:before {
    border-left: 20px solid #fff !important;
  }

  .control-prev.control-arrow:before {
    border-right: 20px solid #fff !important;
  }

  .control-arrow:before {
    border-top: 16px solid transparent !important;
    border-bottom: 16px solid transparent !important;
  }

  .control-arrow:hover,
  .control-arrow:active,
  .control-arrow {
    background: transparent !important;
  }
}
