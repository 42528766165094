@import 'src/App.scss';

.menu {
  z-index: 4;

  .hamburger {
    width: 50px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @include maxwidth(sm) {
      height: 30px;
      width: 30px;
      margin-right: 6px;
    }

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #ececec;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 12px;
    }

    span:nth-child(4) {
      top: 24px;
    }

    &.open {
      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.menu-overlay {
  z-index: 3;
  visibility: hidden;
  overflow: hidden;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  transition: 0.2s ease-out;

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 150px;
    text-align: center;

    @include maxwidth(sm) {
      padding-top: 100px;
    }

    &-links {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      @include minwidth(sm) {
        justify-content: center;
      }

      > a {
        display: block;
        width: fit-content;
        margin: 0 auto;
        padding: 8px 0;
        animation: slide-up 0.3s ease;
        font-size: 24px;
        text-decoration: none;
        color: #a6a6a6;

        @include maxwidth(md) {
          font-size: 20px;
          padding: 8px 0;
        }
      }

      > a:hover {
        color: #fff;
      }

      a:after {
        content: '';
        display: block;
        border-bottom: 2px solid #428aaa;
        margin: 0 auto;
        width: 0;
        transition: all 250ms ease-out;
      }
      a:hover:after {
        width: 70%;
      }
    }

    &-footer {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #428aaa;
      animation: slide-up 0.3s ease;

      @include maxwidth(sm) {
        height: 80px;
      }

      > a {
        > img {
          max-height: 77px;
          max-width: 40px;
        }

        padding: 0 16px;
      }

      > a:hover {
        transform: scale(1.1);
      }
    }
  }
}

.open.menu-overlay {
  visibility: visible;
  opacity: 1;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
