@import 'src/App.scss';

#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #143442;

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    font-family: Raleway;
    color: #fff;

    @include maxwidth(sm) {
      padding-bottom: 30px;
    }
    .company {
      font-size: 24px;
      @include maxwidth(sm) {
        font-size: 18px;
      }
      &-share {
        @include maxwidth(sm) {
          display: flex;
          justify-content: center;
          padding: 16px 0;
        }

        a {
          height: fit-content;
          width: fit-content;
          margin-right: 32px;

          @include maxwidth(sm) {
            margin: 0 16px;
          }

          img {
            max-width: 40px;
            max-height: 40px;
          }
        }
      }
    }

    .logo {
      float: right;
      width: 180px;
    }

    .rights {
      clear: both;
      text-align: center;
      font-weight: 600;
      font-size: 16px;

      @include maxwidth(sm) {
        padding-top: 24px;
      }
    }
  }
}
