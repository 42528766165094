@import 'src/App.scss';

.navBar {
  z-index: 5;
  position: absolute;
  width: 100%;
  height: fit-content;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 3vw;

    @include maxwidth(sm) {
      margin: 16px 3vw;
    }

    &-logo {
      z-index: 5;
      width: 80px;
      width: 120px;

      @include maxwidth(sm) {
        width: 80px;
      }
    }
  }
}
