.workCard {
  width: 100%;
  height: 100%;
  background: #f7fafc;
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.425), 0 3px 6px rgba(77, 77, 77, 0.23);
  transition: transform 200ms ease-out;
  cursor: pointer;

  &-img {
    width: 100%;
    height: auto;
    max-height: 70%;
    box-sizing: border-box;
    object-fit: cover;
  }

  &-textContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 30%;
    background: #f3f3f3;

    &-title {
      margin-bottom: 16;
      font-weight: 600;
      font-size: 20px;
      color: #444;
    }

    &-subtitle {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 12px;
      color: #777;
    }
  }

  &:hover {
    transform: scale(1.07);
  }
}
