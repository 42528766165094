@import 'src/App.scss';

.about {
  background-color: #f3f3f3;

  &-section-1 {
    background-color: #000;
    width: 100%;
    height: 100vh;
    margin: auto;
  }

  &-section-2,
  &-section-3,
  &-section-4,
  &-section-5 {
    width: 100%;
    padding: 48px 0;
    margin: auto;

    @include maxwidth(sm) {
      padding: 24px 0;
    }

    &-title {
      font-weight: 600;
      color: #215166;
    }
  }

  &-section-2 {
    margin-top: 50px;

    @include maxwidth(sm) {
      margin-top: 25px;
    }
  }

  &-section-5 {
    ul {
      list-style-type: none;
      padding: 0;
      > li {
        padding: 4px 0;

        > svg {
          color: #428aaa;
          margin-right: 8px;
        }
      }
    }
  }

  @keyframes img-opacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
