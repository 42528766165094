@import 'src/App.scss';

.home {
  background-color: #f3f3f3;

  &-works-carousel {
    .carousel {
      width: 100%;
      height: 100vh;
      margin: auto;
    }

    .slide {
      height: 100vh;

      &-content {
        width: 100%;
        height: 100%;

        &-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.8;
        }

        &-legend {
          position: absolute;
          bottom: 40px;
          right: 0;
          left: 0;
          font-size: 32px !important;
          font-family: Raleway;
          font-weight: 600;
          text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
          color: #fff;
          background: transparent !important;
          opacity: 1 !important;

          @include maxwidth(sm) {
            text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
            bottom: 100px;
          }
        }
      }
    }

    .control-dots {
      @include maxwidth(sm) {
        bottom: 70px;
      }
    }

    .control-dots > .dot {
      width: 12px;
      height: 12px;
      background: #f3f3f3;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      opacity: 0.5;
    }

    .control-dots > .dot:focus {
      outline: none;
    }
    .control-dots > .dot:hover,
    .control-dots > .dot.selected {
      background: #fff;
      opacity: 1;
    }
  }

  &-section {
    width: 100%;

    &-1 {
      background: rgb(31, 31, 31);
    }

    &-2 {
      height: 90vh;
      max-height: 830px;
      background: linear-gradient(332deg, transparent -200%, rgba(243, 243, 243, 1) 70%),
        url('~assets/imgs/section-2-bg.webp') right no-repeat;

      @include maxwidth(sm) {
        height: 80vh;
      }

      &-title {
        color: #666;
        font-family: Raleway;
        font-size: 48px;
        font-weight: 600;
        padding-top: 150px;

        @include maxwidth(sm) {
          padding-top: 80px;
          font-size: 30px;
        }
      }

      &-body {
        padding-top: 4vh;
        font-family: Raleway;
        font-size: 24px;
        color: #616161;
        max-width: 720px;

        @include maxwidth(sm) {
          font-size: 16px;
        }
      }
    }

    &-3 {
      padding: 200px 0;
      background-color: #fff;

      @include maxwidth(sm) {
        padding: 80px 0;
      }

      &-title {
        color: #666;
        font-family: Raleway;
        font-size: 48px;
        font-weight: 600;
        padding-bottom: 80px;

        @include maxwidth(sm) {
          font-size: 30px;
          padding-bottom: 20px;
        }
      }

      &-cardContainer {
        > div:nth-child(2) {
          margin-top: 24px;
        }

        > div {
          @include maxwidth(sm) {
            margin-top: 24px !important;
          }
        }
      }

      &-card {
        display: flex;

        > img {
          filter: invert(44%) sepia(10%) saturate(646%) hue-rotate(165deg) brightness(101%)
            contrast(93%);
          max-width: 40px;
          max-height: 40px;
          object-position: top;
          object-fit: contain;
        }

        > svg {
          font-size: 40px;
          color: #677784;
          max-width: 40px;
          max-height: 40px;
        }
        &-title {
          // font-family: RobotoCondensedLight;
          margin-bottom: 0px;
          color: #777;
          font-size: 20px;
          font-weight: 500;
        }

        &-subtitle {
          font-weight: 400;
          font-size: 16px;
          color: #777;
        }

        &-mainContent {
          min-height: 150px;
          padding-left: 24px;
          padding-right: 8px;

          @include maxwidth(sm) {
            min-height: auto;
          }
        }
      }
    }

    &-4 {
      padding: 150px 0;

      @include maxwidth(sm) {
        padding: 80px 0;
      }

      &-title {
        color: #666;
        font-family: Raleway;
        font-size: 48px;
        font-weight: 600;
        padding-bottom: 80px;

        @include maxwidth(sm) {
          font-size: 30px;
          padding-bottom: 60px;
        }
      }

      .container .row > div {
        @include maxwidth(sm) {
          margin-bottom: 80px;
        }
      }

      .button-container {
        margin-top: 80px;

        @include maxwidth(sm) {
          margin-top: 30px;
        }

        .button {
          padding: 12px 32px;
          border: 2px solid #777;
          border-radius: 2px;
          margin: 0 auto;
          font-size: 12px;
          color: #777;
          font-weight: 600;

          &:hover {
            @include minwidth(sm) {
              opacity: 0.5;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
