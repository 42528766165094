@import 'src/App.scss';

.work {
  background-color: #f3f3f3;

  &-overlay {
    position: fixed;
    display: block;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(20, 22, 24, 0.94);
    z-index: 4;

    &.hidden {
      transition: opacity 0s;
      opacity: 0;
      visibility: hidden;
    }

    &.visible {
      transition: opacity 200ms ease-out;
      opacity: 1;
      visibility: visible;

      .work-overlay-carousel {
        transition: opacity 1s ease-out;
        opacity: 1;
      }

      .thumbs {
        @for $i from 1 through 30 {
          .thumb:nth-child(#{$i}) {
            transition: opacity 200ms ease-out;
            opacity: 1 !important;
            transition-delay: $i * 50ms !important;
          }
        }
      }
    }

    &-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 24px;
      height: 100px;
      width: 100%;

      @include maxwidth(sm) {
        height: 50px;
      }

      .hamburger {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;

        @include maxwidth(sm) {
          width: 30px;
          height: 25px;
        }

        span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: #ececec;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
        }

        span:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        span:nth-child(2) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }

    &-carousel {
      opacity: 0;
      .carousel-slider {
        .slide {
          background: none;
          &-content {
            &-image {
              width: 100%;
              height: 100%;
              max-height: calc(100vh - 100px - 200px);
              padding: 32px 0;
              object-fit: contain;

              @include maxwidth(sm) {
                max-height: calc(100vh - 50px - 140px);
              }
            }
          }
        }
      }

      .carousel:nth-of-type(2) {
        position: absolute;
        bottom: 0;
        height: 200px;

        @include maxwidth(sm) {
          height: 140px;
        }

        .thumbs-wrapper {
          @include maxwidth(sm) {
            margin: 20px 58px;
          }
        }

        .thumbs {
          > .thumb {
            opacity: 0;
            border: none;

            &.selected {
              border: 2px solid #fff;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }

  &-section-1 {
    background-color: #000;
    width: 100%;
    height: 100vh;
    margin: auto;

    .parallax {
      height: 100vh;
      width: 100%;
      overflow: hidden;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.7;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;

        background: radial-gradient(
          ellipse at center,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }

      &-content {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        width: 50%;
        margin: 0 auto;
        padding: 80px 32px;

        @include maxwidth(sm) {
          width: 90%;
          padding: 32px 8px;
          text-align: center;
        }

        &-title {
          text-align: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          font-size: 60px;
          font-weight: 300;
          font-family: Raleway;
          margin-bottom: 0;

          &:first-child {
            margin-bottom: 24px;
          }

          @include maxwidth(sm) {
            font-size: 24px;
          }
        }

        &-subtitle {
          color: #fff;
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 6px;
          text-transform: uppercase;
          text-align: center;
        }
      }

      &-footer {
        position: absolute;
        bottom: 8px;
        right: 0;
        left: 0;
        color: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &-button {
          background: none;
          border: none;
          color: #fff;
          cursor: pointer;

          &:focus,
          &:active {
            outline: 0;
          }

          &:hover {
            > img {
              transition-duration: 300ms;
              transition-timing-function: ease-out;
              transform: translateY(8px);
              opacity: 0.8;
            }
            > p {
              opacity: 0.8;
            }
          }

          &-text {
            font-weight: 500;
            font-size: 13px;
            width: 100%;
            text-align: center;
            margin-bottom: 4px;
            color: rgb(207, 203, 197);
          }

          > img {
            width: 40px;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(199deg) brightness(104%)
              contrast(101%);
          }
        }
      }
    }
  }

  .containerMediaRight,
  .containerMediaLeft {
    width: 100%;
    margin: auto;
    padding: 100px 0;

    @include maxwidth(sm) {
      padding: 50px 0;
    }

    &-imgContainer {
      display: flex;
      justify-content: center;

      img {
        border-radius: 50%;
        height: 500px;
        width: 500px;
        object-fit: cover;
        box-shadow: 0px 30px 60px -30px #000000;

        @include maxwidth(sm) {
          margin-top: 16px;
          margin-bottom: 40px;
          width: 80vw;
          height: 80vw;
        }
      }
    }

    &-text {
      font-family: ActaDisplayBook;
      letter-spacing: 0em;
      font-weight: 600;
      display: flex;
      align-items: center;
      font-size: 36px;
      color: #544d44;
      text-align: center;

      @include maxwidth(sm) {
        font-size: 30px;
      }
    }
  }
  .containerMediaRight {
    &-text {
      padding-left: 10%;
      padding-right: 5%;
    }
  }

  .containerMediaLeft {
    &-text {
      padding-left: 5%;
      padding-right: 10%;
    }
  }

  &-section-4 {
    padding: 100px 0 150px 0;

    @include maxwidth(sm) {
      padding: 80px 0;
    }

    &-title {
      text-align: center;
      padding-bottom: 16px;
      font-size: 40px;
    }
  }

  .carousel {
    overflow: hidden;

    .button-next,
    .button-prev {
      opacity: 0;
      background: rgba(56, 51, 45, 0.95);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40%;
      width: 70px;
      height: 100px;
      color: #fff;
      border: none;
      transition-duration: 300ms;
      transition-timing-function: ease-out;

      &:focus {
        outline: 0;
      }

      img {
        width: 30px;
        filter: invert(100%) sepia(13%) saturate(2%) hue-rotate(288deg) brightness(108%)
          contrast(100%);
      }
    }

    .button-prev {
      left: -70px;
    }

    .button-next {
      right: -70px;
    }
  }
  .carousel:hover {
    .button-next,
    .button-prev {
      opacity: 1;
    }

    .button-prev {
      left: 4px;
    }

    .button-next {
      right: 4px;
    }
  }

  &-gallery {
    &-slide-wrapper {
      margin: 0 4px;
      display: inline-block;
      overflow: hidden;
      border: none;

      &:focus {
        outline: 0;
      }

      &:hover .work-gallery-slide-wrapper-image {
        transform: scale(1.2);
        opacity: 0.8;
      }

      &-image {
        transition: all 0.3s ease-out;
        vertical-align: middle;
      }
    }
  }
}
