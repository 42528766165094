.media-card {
  width: 100%;
  height: 100%;
  position: relative;

  &-img {
    width: 100%;
    height: 100%;
    transition: opacity ease-out 200ms;
  }

  &-description {
    visibility: hidden;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 0 24px 24px 24px;
    transform: translateY(30px);
    transition: 0.2s ease-out;
    opacity: 0;
    > p {
      margin-bottom: 0;
    }

    &-text {
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    }

    &-tag {
      background: #428aaa;
      border-radius: 16px;
      padding: 3px 12px;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      width: fit-content;
      text-align: center;
    }
  }

  &:hover {
    cursor: pointer;

    .media-card-description {
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
    }

    .media-card-img {
      opacity: 0.2;
    }
  }
}
