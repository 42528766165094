@import 'src/App.scss';

$gridSpacing: 56px;

.portfolio {
  background-color: #fff;

  &-section-1 {
    background-color: #4d4d4d;
    width: 100%;
    height: 100vh;
    margin: auto;
  }

  &-section-2 {
    clear: both;
    width: 100%;
    min-height: 100vh;
    margin: auto;
    padding: $gridSpacing;

    @include maxwidth(sm) {
      padding: 8px;
      padding-top: 56px;
    }

    &-types {
      &-sm {
        .dropdown {
          width: calc(100% - 6px);
          padding: 0 2px;
          margin: 0 auto;
          .btn {
            width: 100%;
            margin: 0 auto;
            border-radius: 0px;
            background: rgb(77, 77, 77);
            text-align: left;

            &::after {
              position: absolute;
              right: 5%;
              top: 45%;
              border-top: 0.4em solid;
              border-right: 0.4em solid transparent;
              border-left: 0.4em solid transparent;
            }
          }
        }
        .dropdown-menu {
          width: 100%;
          margin: 6px 0;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

          .dropdown-item {
            padding: 14px 24px;
            &:not(:last-child) {
              border-bottom: 1px solid #e6e6e6;
            }
          }
        }
      }
      &-md {
        display: flex;
        justify-content: center;
        padding: 60px 0 32px 0;

        button:hover,
        button:active,
        button:focus {
          text-decoration: none;
          border: none;
          outline: none;
        }
        button {
          margin: 0 16px;
          background-color: transparent;
          border: none;
          span {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            color: #212529;
            text-decoration: none;
            cursor: pointer;

            &:after {
              content: '';
              display: block;
              border-bottom: 2px solid #428aaa;
              margin: 0 auto;
              width: 0;
              transition: all 0.3s ease-out 0s;
            }

            &:hover:after,
            &.selected:after {
              width: 90%;
            }
          }
        }
      }
    }

    &-grid {
      columns: 3 200px;
      column-gap: $gridSpacing;
      padding: 16px;

      @include maxwidth(sm) {
        padding: 16px 8px;
        padding-top: 24px;
      }

      > a {
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-bottom: $gridSpacing;
      }

      > .portrait {
        // height: 600px;
      }

      > .landscape,
      > .even {
        // height: 35vh;
      }
      //   @for $i from 1 through 36 {
      //     div:nth-child(#{$i}) {
      //       $h: (random(400) + 100) + px;
      //       height: $h;
      //       line-height: $h;
      //     }
      //   }
    }
  }
}

@keyframes img-opacity {
  0% {
    //transform: scale(1.1);
    opacity: 0;
  }

  100% {
    //transform: scale(1);
    opacity: 1;
  }
}
